import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./console.css";
import { FaRocket } from "react-icons/fa";
import DynamicForm from './DynamicForm';

const requiredParameters = {
    gcp: {
        vpc: ['network_name', 'subnet_name', 'ip_cidr_range'],

        "cloud-armor": [
            "instance_template_name_prefix",
            "machine_type",
            "network_name",
            "instance_group_manager_name",
            "instance_base_name",
            "target_size",
            "backend_service_name",
            "health_check_name",
            "security_policy_name",
        ],
    },
    aws: {
        "aws-vpc": ["vpc_cidr", "subnet_cidr"],
        "aws-waf": ["rule_metric_name", "acl_metric_name"],
    },
    azure: {
        // "azure-vpc": [ "vpc_cidr", "subnet_cidr"],
        'azure-vpc': ['vpc_cidr', 'subnet_cidr'],

        "application-gateway": [
            "resource_group_name",
            "public_ip_name",
            "app_gateway_name",
        ],
    },
};

const getStatusClass = (status) => {
    switch (status) {
        case 'create-success':
            return 'success';
        case 'destroy-success':
            return 'status-destroy-success';
        case 'create-pending':
        case 'destroy-pending':
            return 'warning';
        case 'create-failure':
        case 'destroy-failure':
            return 'error';
        default:
            return '';
    }
};
const serviceDisplayNames = {
    'vpc': 'GCP VPC',
    'cloud-armor': 'GCP Cloud Armor',
    'aws-vpc': 'AWS VPC',
    'aws-waf': 'AWS WAF',
    'azure-vpc': 'Azure VPC',
    'application-gateway': 'Azure Application Gateway'
};

const Newscreen = () => {

    const servicesByPlatform = {
        gcp: ['vpc', 'cloud-armor'],
        aws: ['aws-vpc', 'aws-waf'],
        azure: ['azure-vpc', 'application-gateway'],
    };

    const [platform, setPlatform] = useState("");
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedService, setSelectedService] = useState("");

    const [infra, setInfra] = useState("create");
    const [etl, setEtl] = useState("create");
    const [prefix, setPrefix] = useState("");
    const [services, setServices] = useState([]);
    const [logs, setLogs] = useState("");
    const [showLogs, setShowLogs] = useState(false);
    const [serviceStatus, setServiceStatus] = useState({});
    const [loadingStatus, setLoadingStatus] = useState({});
    const [failureReasons, setFailureReasons] = useState({});
    const [sessionCount, setSessionCount] = useState(1);
    const [deployments, setDeployments] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const limit = 100;
    const [loading, setLoading] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"));
    console.log("🚀 ~ Newscreen ~ user:", user)

    console.log("🚀 ~ Newscreen ~ user?.user_metadata?.name:", user?.user_metadata?.name, user?.user_metadata?.userName)

    const [generatedName, setGeneratedName] = useState("");

    const serviceParameters = {
        "aws-vpc": {
            vpc_cidr: "10.19.0.0/16",
            subnet_cidr: "10.0.1.0/24",
        },
        "aws-waf": {
            rule_metric_name: `${generatedName}-waf-rule-metric`,
            acl_metric_name: `${generatedName}-acl-metric`,
        },
        "vpc": {
            network_name: `${generatedName}-vpc-network`,
            subnet_name: `${generatedName}-vpc-subnet`,
            ip_cidr_range: "10.19.0.0/16",
        },
        "cloud-armor": {
            instance_template_name_prefix: `${generatedName}-zagit-name`,
            machine_type: "n1-standard-1",
            network_name: "default",
            instance_group_manager_name: `${generatedName}-igm`,
            instance_base_name: `${generatedName}-base-name`,
            target_size: 2,
            backend_service_name: `${generatedName}-backend-service`,
            health_check_name: `${generatedName}-health-check`,
            security_policy_name: `${generatedName}-security-policy`,
        },
        "azure-vpc": {
            vpc_cidr: "10.19.0.0/16",
            subnet_cidr: "10.1.1.0/24",
        },
        "application-gateway": {
            resource_group_name: `${generatedName}-my-resource-group`,
            public_ip_name: `${generatedName}-my-public-ip`,
            app_gateway_name: `${generatedName}-my-app-gateway`,
        },
    };

    const generaterandomname = (username) => {
        const randomId = Math.random().toString(36).substr(2, 4);
        return `${username}-${randomId}`
            .toLowerCase()
            .replace(/[^a-z0-9-]/g, "")
            .substr(0, 63);
    };





    const [inputParams, setInputParams] = useState({});
    const token = JSON.parse(localStorage.getItem("app-access-token"));

    const [selectedValue, setSelectedValue] = useState('false');
    console.log("🚀 ~ Newscreen ~ selectedValue:", selectedValue)

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        if ((user?.user_metadata?.name || user?.user_metadata?.userName) && selectedValue == "true") {
            const newName = generaterandomname((user?.user_metadata?.name || user?.user_metadata?.userName));
            setGeneratedName(newName);
        }
    }, [selectedValue]);


    const handleSessionCountChange = (e) => {
        setSessionCount(e.target.value);
    };

    useEffect(() => {
        if (platform) {
            setLogs("");
            setFailureReasons({});

            fetch(
                `${process.env.REACT_APP_BASE_URL_MAIN}/services?platform=${platform}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setServices(data.services);
                    setSelectedServices(data.services);
                    const initialStatus = {};
                    data.services.forEach((srv) => {
                        initialStatus[srv] = "idle";
                    });
                    setServiceStatus(initialStatus);
                    setLoadingStatus(initialStatus);
                    setInfra("create");
                    // setIsDeployed(false);
                })
                .catch((error) => {
                    toast.error(`Error fetching services: ${error.message}`);
                });
        } else {
            setServices([]);
        }
    }, [platform, token]);




    const handleParamChange = (event, service, sessionIndex) => {
        const { name, value } = event.target;
        setInputParams(prevParams => ({
            ...prevParams,
            [sessionIndex]: {
                ...prevParams[sessionIndex],
                [service]: {
                    ...(prevParams[sessionIndex]?.[service] || {}),
                    [name]: value
                }
            }
        }));
    };

    const handleDeploy = async (e) => {
        e.preventDefault();

        if (selectedServices.length === 0) {
            toast.error("Please select at least one service before deploying.");
            return;
        }

        setLogs("");
        setFailureReasons({});

        const deployPromises = [];

        for (let i = 0; i < sessionCount; i++) {
            selectedServices.forEach((service) => {

                const serviceRequiredParams = requiredParameters[platform]?.[service] || [];
                const specificParams = serviceParameters[service] || {};
                const payload = {
                    infra: infra,
                    input_from_user: selectedValue == "true",
                    user: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    username: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    sessionCount: sessionCount,
                    type: "asynchronous",
                    ...specificParams,
                    ...inputParams[i]?.[service]
                };

                // Validate required fields
                // const missingParams = serviceRequiredParams.filter(param => !payload[param]);
                // if (missingParams.length > 0) {
                //     toast.error(`Missing required parameters for ${service}: ${missingParams.join(', ')}`);
                //     return;
                // }

                setLoadingStatus((prevState) => ({
                    ...prevState,
                    [service]: "running",
                }));

                deployPromises.push(
                    fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${platform}/${service}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(payload),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            console.log("🚀 ~ .then ~ data:", data);
                            const { message, status, error, details, deploymentId } = data;

                            if (message === "Deployment created successfully") {
                                toast.success(
                                    `${service} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                                );
                                setServiceStatus((prevState) => ({
                                    ...prevState,
                                    [service]: "success",
                                }));
                            } else if (error) {
                                setServiceStatus((prevState) => ({
                                    ...prevState,
                                    [service]: "failure",
                                }));
                                setFailureReasons((prevReasons) => ({
                                    ...prevReasons,
                                    [service]: details || ["Unknown error occurred."],
                                }));

                                toast.error(
                                    `${service} deployment failed: ${details || error} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                                );
                            } else {
                                console.error(`Unexpected status from ${service}: ${status}`);
                                toast.error(`Unexpected status from ${service}: ${status}`);
                            }

                            setLoadingStatus((prevState) => ({
                                ...prevState,
                                [service]: "idle",
                            }));
                        })
                        .catch((error) => {
                            console.error("Error in deployment:", error);
                            setServiceStatus((prevState) => ({
                                ...prevState,
                                [service]: "failure",
                            }));

                            setLoadingStatus((prevState) => ({
                                ...prevState,
                                [service]: "idle",
                            }));

                            setFailureReasons((prevReasons) => ({
                                ...prevReasons,
                                [service]: ["Network error occurred."],
                            }));

                            toast.error(`${service} deployment failed due to network error.`);
                        })
                );
            });
        }

        await Promise.all(deployPromises);
    };


    const handleDeploySynchronous = async (e) => {
        e.preventDefault();

        if (selectedServices.length === 0) {
            toast.error("Please select at least one service before deploying.");
            return;
        }

        setLogs("");
        setFailureReasons({});

        for (let i = 0; i < sessionCount; i++) {
            for (const service of selectedServices) {

                const serviceRequiredParams = requiredParameters[platform]?.[service] || [];
                const specificParams = serviceParameters[service] || {};
                const payload = {
                    infra: infra,
                    input_from_user: selectedValue === "true",
                    user: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    username: user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'),
                    sessionCount: sessionCount,
                    type: "Synchronous",
                    ...specificParams,
                    ...inputParams[i]?.[service]
                };

                // Validate required fields
                // const missingParams = serviceRequiredParams.filter(param => !payload[param]);
                // if (missingParams.length > 0) {
                //     toast.error(`Missing required parameters for ${service}: ${missingParams.join(', ')}`);
                //     return;
                // }

                setLoadingStatus((prevState) => ({
                    ...prevState,
                    [service]: "running",
                }));

                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${platform}/${service}`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(payload),
                    });

                    const data = await response.json();
                    console.log("🚀 ~ .then ~ data:", data);
                    const { message, status, error, details, deploymentId } = data;

                    if (message === "Deployment created successfully") {
                        toast.success(
                            `${service} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                        );
                        setServiceStatus((prevState) => ({
                            ...prevState,
                            [service]: "success",
                        }));
                    } else if (error) {
                        setServiceStatus((prevState) => ({
                            ...prevState,
                            [service]: "failure",
                        }));
                        setFailureReasons((prevReasons) => ({
                            ...prevReasons,
                            [service]: details || ["Unknown error occurred."],
                        }));

                        toast.error(
                            `${service} deployment failed: ${details || error} ${deploymentId ? "(ID: " + deploymentId + ")" : ""}`
                        );
                    } else {
                        console.error(`Unexpected status from ${service}: ${status}`);
                        toast.error(`Unexpected status from ${service}: ${status}`);
                    }

                } catch (error) {
                    console.error("Error in deployment:", error);
                    setServiceStatus((prevState) => ({
                        ...prevState,
                        [service]: "failure",
                    }));

                    setFailureReasons((prevReasons) => ({
                        ...prevReasons,
                        [service]: ["Network error occurred."],
                    }));

                    toast.error(`${service} deployment failed due to network error.`);
                } finally {
                    setLoadingStatus((prevState) => ({
                        ...prevState,
                        [service]: "idle",
                    }));
                }
            }
        }
    };



    const handleCheckboxChange = (srv) => {
        setSelectedServices((prevSelected) =>
            prevSelected.includes(srv)
                ? prevSelected.filter((service) => service !== srv) // Deselect
                : [...prevSelected, srv] // Select
        );
    };


    const getServiceButtonClass = (srv) => {
        if (loadingStatus[srv] === "running") return "service-btn-running";

        switch (serviceStatus[srv]) {
            case "success":
                return "service-btn-success";
            case "failure":
                return "service-btn-failure";
            default:
                return "service-btn-idle";
        }
    };


    const handleTabChange = (platform) => {
        setPlatform(platform);
        setLogs("");
        setFailureReasons({});
    };



    const [activeTab, setActiveTab] = useState('all');

    const servicess = [...new Set(deployments?.map(d => d?.service))];
    const [sortConfig, setSortConfig] = useState({ key: 'service', direction: 'asc' });
    const [selectedPlatform, setSelectedPlatform] = useState('');
    const [selectedInfra, setSelectedInfra] = useState('');
    const [selectedPrefix, setSelectedPrefix] = useState('');

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    const sortedDeployments = (deployments) => {
        return deployments.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };


    const filteredDeployments = () => {
        let filtered = deployments;

        if (selectedPlatform) {
            filtered = filtered.filter(deployment => deployment.platform === selectedPlatform);
        }

        if (selectedInfra) {
            filtered = filtered.filter(deployment => deployment.infra === selectedInfra);
        }
        if (selectedPrefix) {
            filtered = filtered.filter(deployment => deployment.prefix === selectedPrefix);
        }

        if (activeTab !== 'all') {
            filtered = filtered.filter(deployment => deployment.status === activeTab || deployment.service === activeTab);
        }

        return sortedDeployments(filtered);
    };

    const allServices = Object.entries(servicesByPlatform).flatMap(([platform, services]) =>
        services.map(service => ({
            platform,
            service,
            displayName: serviceDisplayNames[service] || service,
        }))
    );

    useEffect(() => {
        fetchDeployments();
    }, [platform, selectedServices, activeTab, page]);

    const fetchDeployments = () => {
        const queryParams = new URLSearchParams();
        setLoading(true)
        queryParams.append("limit", limit);
        queryParams.append("page", page);
        queryParams.append("username", user?.user_metadata?.name?.replace(/\s+/g, '-') || user?.user_metadata?.userName?.replace(/\s+/g, '-'));

        if (
            activeTab === 'create-pending' ||
            activeTab === 'create-success' ||
            activeTab === 'create-failure' ||
            activeTab === 'destroy-pending' ||
            activeTab === 'destroy-success' ||
            activeTab === 'destroy-failure'
        ) {
            queryParams.append("status", activeTab);
        } else if (servicess.includes(activeTab)) {
            queryParams.append("service", activeTab);
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/deploy?${queryParams.toString()}`, {
            headers: {
                authorization: `Bearer ${token}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setDeployments(data?.results || []);
                setTotalPages(data?.totalPages || 1);
                setLoading(false)


            })
            .catch((error) => {
                toast.error(`Error fetching deployments: ${error.message}`);
                setLoading(false)

            });
    };


    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setPage(newPage);
        }
    };


    const handleDeleteDeploy = (dd) => {
        console.log("🚀 ~ handleDeleteDeploy ~ dd:", dd)

        const payload = {
            service: dd?.service,
            infra: "destroy",
            prefix: dd?.workspaceName,
            sessionCount: dd?.sessionCount,
            platform: dd?.platform,
            // user: localStorage.getItem("user"),
            user: dd?.workspaceName,
            username: dd?.workspaceName,
            // type: dd?.type,
            deploymentId: dd?.deploymentId
        };

        fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy/${dd?.platform}/${dd?.service}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                const { message, status, deploymentId } = data;

                if (status === "success") {
                    setShowLogs(true);

                    toast.success(
                        message
                            ? `${message} ${deploymentId ? `(ID: ${deploymentId})` : ""}`
                            : `${dd?.service} delete service started successfully!`
                    );


                    setServiceStatus((prevState) => ({
                        ...prevState,
                        [dd?.service]: "success",
                    }));

                    setLoadingStatus((prevState) => ({
                        ...prevState,
                        [dd?.service]: "idle",
                    }));

                    const isProduction = process.env.NODE_ENV === "development";
                    const wsUrl = isProduction
                        ? "wss://console.zagit.ai/ws"
                        : "ws://localhost:3032/ws";

                    const ws = new WebSocket(wsUrl);
                    ws.onmessage = (event) => {
                        setLogs((prevLogs) => prevLogs + "\n" + event.data);
                    };
                    ws.onclose = () => {
                        setLogs(
                            (prevLogs) => prevLogs + `\n${dd?.service} delete service finished.`
                        );

                        toast.success(
                            message
                                ? `${message} ${deploymentId ? `(ID: ${deploymentId})` : ""}`
                                : `${dd?.service} delete service started successfully!`
                        );

                    };
                } else {
                    setServiceStatus((prevState) => ({
                        ...prevState,
                        [dd?.service]: "failure",
                    }));

                    setLoadingStatus((prevState) => ({
                        ...prevState,
                        [dd?.service]: "idle",
                    }));

                    setFailureReasons((prevReasons) => ({
                        ...prevReasons,
                        [dd?.service]: data?.reasons || ["Unknown error occurred."],
                    }));

                    toast.error(
                        message
                            ? `${message} ${deploymentId ? `(ID: ${deploymentId})` : ""}`
                            : `${dd?.service} delete service failed!`
                    );

                }
            })
            .catch((error) => {
                console.error("Deletion failed due to network error:", error);

                setServiceStatus((prevState) => ({
                    ...prevState,
                    [dd?.service]: "failure",
                }));

                setLoadingStatus((prevState) => ({
                    ...prevState,
                    [dd?.service]: "idle",
                }));

                setFailureReasons((prevReasons) => ({
                    ...prevReasons,
                    [dd?.service]: ["Network error occurred."],
                }));

                toast.error(`${dd?.service} delete service failed due to network error.`);
            });
    };

    return (
        <div className="tabs_screen_main_div">
            <div className="tabs_img_box">
                <div className="tabs_flex_box">
                    <Button
                        eventKey="contact"
                        title="Azure"
                        onClick={() => handleTabChange("azure")}
                    >
                        <img src="/images/app-service.png" />
                    </Button>
                    <Button
                        eventKey="profile"
                        title="AWS"
                        onClick={() => handleTabChange("aws")}
                    >
                        <img src="/images/aws-removebg-preview.png" />
                    </Button>

                    <Button
                        eventKey="home"
                        title="GCP"
                        onClick={() => handleTabChange("gcp")}
                    >
                        <img src="/images/googl-removebg-preview.png" />
                    </Button>


                </div>


                <div className="tabs_content_div">
                    <Container style={{ height: '100%' }} >
                        <div className="tabs-content" style={{ height: '100%' }}>
                            <div className="sidebar_content" style={{ height: '100%', overflowY: 'auto' }}>
                                <Row className="pb-5">
                                    {services.map((srv, index) => (
                                        <Col
                                            key={index}
                                            className="text-center p-0 mb-3"
                                            draggable
                                            onDragStart={(e) =>
                                                e.dataTransfer.setData("service", srv)
                                            }
                                            onDragEnd={() => handleCheckboxChange(srv)}
                                        >
                                            <Button
                                                className={`service-btn ${getServiceButtonClass(srv)}`}
                                            >
                                                <div className="img_box" style={{ minHeight: "40px" }}>
                                                    <Form.Check
                                                        aria-label={`option-${index}`}
                                                        onChange={() => handleCheckboxChange(srv)}
                                                        checked={selectedServices.includes(srv)}
                                                    />

                                                    <img
                                                        src={`/images/${srv}.png`}
                                                        alt={srv}
                                                        className="service-img"
                                                    />
                                                </div>

                                                <h5 className="service-text">
                                                    {srv}{" "}
                                                    {loadingStatus[srv] === "running" && "Running..."}
                                                </h5>
                                            </Button>
                                        </Col>
                                    ))}
                                </Row>

                                {selectedServices.length > 0 && (
                                    <div className="dynamic-form-section">
                                        {selectedServices.map((srv) => (
                                            <div key={`form-${srv}`}>
                                                <h3>{`Configure ${platform} - ${srv}`}</h3>

                                                {Array.from({ length: sessionCount }).map((_, sessionIndex) => (
                                                    <div key={`form-${srv}-${sessionIndex}`}>
                                                        <h4>{`Session ${sessionIndex + 1}`}</h4>
                                                        <DynamicForm
                                                            setInputParams={setInputParams}
                                                            platform={platform}
                                                            service={srv}
                                                            sessionIndex={sessionIndex}
                                                            inputParams={inputParams}
                                                            handleParamChange={(e) =>
                                                                handleParamChange(e, srv, sessionIndex)
                                                            }
                                                            selectedValue={selectedValue}
                                                            username={user?.user_metadata?.name || user?.user_metadata?.userName}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                )}




                            </div>
                            <div className="deployment_flex">
                                {/* <div className="logs-container">
                                    {showLogs && (
                                        <>
                                            <h2>Deployment Logs</h2>
                                            <textarea
                                                rows="15"
                                                value={logs}
                                                readOnly
                                                style={{
                                                    width: "100%",
                                                    padding: "10px",
                                                    fontSize: "14px",
                                                    height: "100%",
                                                }}
                                            />
                                        </>
                                    )}
                                </div> */}
                                {/* {Object.keys(failureReasons).length > 0 && (
                                    <div className="failure-reasons">
                                        <h4>Deployment Errors:</h4>
                                        {Object.entries(failureReasons).map(([service, reasons]) => (
                                            <div key={service}>
                                                <h5>{service}:</h5>
                                                <ul className="deployment_box">
                                                    {reasons.map((reason, idx) => (
                                                        <li key={idx}>{reason}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        ))}
                                    </div>
                                )} */}

                            </div>
                        </div>
                    </Container>
                </div>
            </div>



            {
                selectedServices.length > 0 && (
                    <div
                        className="btns_deploy"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            margin: "auto",
                            margin: "50px auto 20px",
                        }}
                    >

                        <label htmlFor="sessionCount">Session Count: </label>
                        <input
                            type="number"
                            id="sessionCount"
                            value={sessionCount}
                            onChange={handleSessionCountChange}
                        />

                        <div>
                            <label htmlFor="dropdown">Input From User</label>
                            <select id="dropdown" value={selectedValue} onChange={handleChange}>
                                <option value="false">False</option>
                                <option value="true">True</option>
                            </select>

                            {/* <p>Selected value: {selectedValue}</p> */}
                        </div>

                        <Button
                            className="btn btn-dark m-2"
                            onClick={handleDeploy}
                            title="Deploy"
                            disabled={Object.values(loadingStatus).includes("running")}
                        >
                            <FaRocket />
                            Deploy (Parallel)

                        </Button>

                        <Button
                            className="btn btn-dark m-2"
                            onClick={handleDeploySynchronous}
                            title="Deploy"
                            disabled={Object.values(loadingStatus).includes("running")}
                        >
                            <FaRocket />
                            Deploy (Syncronous)

                        </Button>

                    </div>
                )
            }



            {/* listing */}
            <Container>
                <h2 style={{ marginBottom: "30px", marginTop: "80px", fontSize: "24px", fontWeight: "bold", color: "#343a40", textAlign: "center" }}>
                    Deployment Listings
                </h2>

                {/* Tab Navigation */}
                <div style={{ margin: "30px auto", textAlign: "center" }}>
                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'all' ? 'active' : ''}`}
                        onClick={() => setActiveTab('all')}
                    >
                        All
                    </Button>

                    {allServices.map(({ service, displayName }) => (
                        <Button
                            key={service}
                            className={`btn btn-dark m-2 ${activeTab === service ? 'active' : ''}`}
                            onClick={() => setActiveTab(service)}
                        >
                            {displayName}
                        </Button>
                    ))}
                    <br />

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'create-pending' ? 'active' : ''}`}
                        onClick={() => setActiveTab('create-pending')}
                    >
                        create-pending
                    </Button>

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'create-success' ? 'active' : ''}`}
                        onClick={() => setActiveTab('create-success')}
                    >
                        create-success
                    </Button>

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'create-failure' ? 'active' : ''}`}
                        onClick={() => setActiveTab('create-failure')}
                    >
                        create-failure
                    </Button>

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'destroy-pending' ? 'active' : ''}`}
                        onClick={() => setActiveTab('destroy-pending')}
                    >
                        destroy-pending
                    </Button>

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'destroy-success' ? 'active' : ''}`}
                        onClick={() => setActiveTab('destroy-success')}
                    >
                        destroy-success
                    </Button>

                    <Button
                        className={`btn btn-dark m-2 ${activeTab === 'destroy-failure' ? 'active' : ''}`}
                        onClick={() => setActiveTab('destroy-failure')}
                    >
                        destroy-failure
                    </Button>
                </div>


                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <select value={selectedPlatform} onChange={(e) => setSelectedPlatform(e.target.value)} className="form-select m-2">
                        <option value="">Filter by Platform</option>
                        <option key="aws" value="aws">aws</option>
                        <option key="gcp" value="gcp">gcp</option>
                        <option key="azure" value="azure">azure</option>
                    </select>


                    {/* <select
                        value={selectedServices}
                        onChange={(e) => {
                            const options = Array.from(e.target.selectedOptions, (option) => option.value);
                            setSelectedServices(options); // Set selectedServices as an array of selected values
                        }}
                        className="form-select m-2"
                    >
                        <option value="">Filter by Service</option>
                        {Object.entries(serviceDisplayNames).map(([key, displayValue]) => (
                            <option key={key} value={key}>
                                {displayValue}
                            </option>
                        ))}
                    </select> */}


                    <select value={selectedInfra} onChange={(e) => setSelectedInfra(e.target.value)} className="form-select m-2">
                        <option value="">Filter by Infra</option>
                        <option value="create">Create</option>
                        <option value="destroy">Destroy</option>
                    </select>


                </div>


                {loading ? (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                        <Spinner animation="border" role="status" size="lg">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                ) : (
                    filteredDeployments()?.length > 0 ? (
                        <>
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('Sr')}>Sr {sortConfig.key === 'Sr' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th onClick={() => handleSort('platform')}>Platform {sortConfig.key === 'platform' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th onClick={() => handleSort('service')}>Service {sortConfig.key === 'service' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th onClick={() => handleSort('infra')}>Infra {sortConfig.key === 'infra' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th onClick={() => handleSort('prefix')}>Prefix {sortConfig.key === 'prefix' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th onClick={() => handleSort('status')}>Status {sortConfig.key === 'status' ? (sortConfig.direction === 'asc' ? '▲' : '▼') : ''}</th>
                                        <th>User</th>
                                        <th>Deployment Id</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredDeployments().map((deployment, index) => (
                                        <tr key={index} className={getStatusClass(deployment.status)}>
                                            <td data-label="Sr">{index + 1}</td>
                                            <td data-label="Platform">{deployment?.platform}</td>
                                            <td data-label="Service">{deployment?.service}</td>
                                            <td data-label="Infra">{deployment?.infra}</td>
                                            <td data-label="Prefix">{deployment?.prefix}</td>
                                            <td data-label="Status">{deployment?.status}</td>
                                            <td data-label="Status">{user && user?.user_metadata && user?.user_metadata?.name?.replace(/\s+/g, '-')}</td>
                                            <td data-label="Date">{deployment?.deploymentId}</td>
                                            <td>
                                                {deployment.infra === "create" && deployment.status === "create-success" && (
                                                    <button className="btn btn-dark" type="button" onClick={() => handleDeleteDeploy(deployment)}>Destroy</button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                            {/* Pagination */}
                            {activeTab == "all" &&
                                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                    <Button className="btn btn-dark m-2" disabled={page === 1} onClick={() => handlePageChange(page - 1)}>Previous</Button>
                                    <span style={{ margin: '0 15px' }}>Page {page} of {totalPages}</span>
                                    <Button className="btn btn-dark m-2" disabled={page === totalPages} onClick={() => handlePageChange(page + 1)}>Next</Button>
                                </div>
                            }
                        </>
                    ) : (
                        <div style={{
                            textAlign: 'center',
                            padding: '50px',
                            backgroundColor: '#f9f9f9',
                            borderRadius: '10px',
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                            color: '#6c757d',
                        }}>
                            <img
                                src="/images/02.png"
                                alt="No Deployments"
                                style={{ maxWidth: '150px', marginBottom: '20px' }}
                            />
                            <h3>No Deployments Found</h3>
                            <p>Please adjust your filters or check back later.</p>
                        </div>
                    )
                )}
            </Container>

        </div >
    );
};

export default Newscreen;
