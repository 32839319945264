import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

const generaterandomname = (username) => {
    const randomId = Math.random().toString(36).substr(2, 4);
    return `${username}-${randomId}`
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "")
        .substr(0, 63);
};

let levenshteinWords = ["apple", "dog", "banana"];

function getRandomWords(n) {
    const randomWords = [];
    while (randomWords.length < n) {
        const word = levenshteinWords[Math.floor(Math.random() * levenshteinWords.length)];
        randomWords.push(word);
    }
    return randomWords;
}

const requiredParameters = {
    gcp: {
        vpc: ["network_name", "subnet_name", "ip_cidr_range"],
        "cloud-armor": [
            "instance_template_name_prefix",
            "machine_type",
            "network_name",
            "instance_group_manager_name",
            "instance_base_name",
            "target_size",
            "backend_service_name",
            "health_check_name",
            "security_policy_name",
        ],
    },
    aws: {
        "aws-vpc": ["vpc_cidr", "subnet_cidr"],
        "aws-waf": ["rule_metric_name", "acl_metric_name"],
    },
    azure: {
        'azure-vpc': ["vpc_cidr", "subnet_cidr"],
        "application-gateway": [
            "resource_group_name",
            "public_ip_name",
            "app_gateway_name",
        ],
    },
};

const DynamicForm = ({
    platform,
    service,
    inputParams,
    handleParamChange,
    selectedValue,
    username,
    sessionIndex,
}) => {
    const [generatedName, setGeneratedName] = useState("");
    const [defaultParams, setDefaultParams] = useState({});

    const serviceParameters = {
        "aws-vpc": {
            vpc_cidr: "10.19.0.0/16",
            subnet_cidr: "10.0.1.0/24",
        },
        "aws-waf": {
            rule_metric_name: `${generatedName}-waf-rule-metric`,
            acl_metric_name: `${generatedName}-acl-metric`,
        },
        "vpc": {
            network_name: `${generatedName}-vpc-network`,
            subnet_name: `${generatedName}-vpc-subnet`,
            ip_cidr_range: "10.19.0.0/16",
        },
        "cloud-armor": {
            instance_template_name_prefix: `${generatedName}-zagit-name`,
            machine_type: "n1-standard-1",
            network_name: "default",
            instance_group_manager_name: `${generatedName}-igm`,
            instance_base_name: `${generatedName}-base-name`,
            target_size: 2,
            backend_service_name: `${generatedName}-backend-service`,
            health_check_name: `${generatedName}-health-check`,
            security_policy_name: `${generatedName}-security-policy`,
        },
        "azure-vpc": {
            vpc_cidr: "10.19.0.0/16",
            subnet_cidr: "10.1.1.0/24",
        },
        "application-gateway": {
            resource_group_name: `${generatedName}-my-resource-group`,
            public_ip_name: `${generatedName}-my-public-ip`,
            app_gateway_name: `${generatedName}-my-app-gateway`,
        },
    };

    const requiredParams = requiredParameters[platform]?.[service] || [];

    useEffect(() => {
        if (username && selectedValue === "true") {
            const newName = generaterandomname(username);
            setGeneratedName(newName);
        }
    }, [username, selectedValue]);



    useEffect(() => {
        if (selectedValue === "true" && username) {
            const newDefaults = {};
            requiredParams.forEach((param) => {
                if (typeof serviceParameters[service]?.[param] === 'string') {
                    newDefaults[param] = serviceParameters[service][param].replace(/.*-/, `${generatedName}-`);
                } else {
                    // Handle cases where service parameter is not a string or is undefined
                    newDefaults[param] = '';
                }
            });
            setDefaultParams(newDefaults);
        }
    }, [selectedValue, username, requiredParams, generatedName, serviceParameters, service]);




    const formatLabel = (str) =>
        str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());

    return (
        <Form>
            {requiredParams.map((param) => (
                selectedValue === "true" && (
                    <Form.Group key={`${param}-${sessionIndex}`}>
                        <Form.Label>{formatLabel(param)}</Form.Label>
                        <Form.Control
                            type="text"
                            name={param}
                            value={
                                inputParams?.[sessionIndex]?.[service]?.[param] ||
                                defaultParams[param] ||
                                ""
                            }
                            onChange={handleParamChange}
                            placeholder={`Enter ${formatLabel(param)} for Session ${sessionIndex + 1}`}
                        />
                    </Form.Group>
                )))}
        </Form>
    );
};

export default DynamicForm;
